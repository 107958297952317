import React from "react";
import { useNavigate } from "react-router-dom";
import AuthTemplate from "../../../components/auth_template/auth_template.component";
import Assets from "../../../imports/assets.imports";
import { Button } from "../../../imports/component.imports";
import "./login.screen.scss";

const LoginIntro = () => {
  const navigate = useNavigate();
  return (
    <AuthTemplate>
      <div className="login_intro_container">
        <div className="login_intro_wrapper">
          <div className="logo_content">
            <img src={Assets.Logo} alt="logo" className="logo" />
          </div>
          <div className="description_text">
            The day is here. Log in to see “Superstar” Ragheb Alama, “LM3ALLEM”
            Saad Lamjarred and Michel Fadel, “Step into the Metaverse” and give
            the performance of a lifetime as digital avatars!
          </div>
          <div className="btn_container">
            <div className="btn_wrap">
              <Button
                text="login with email"
                onClick={() => navigate("../email")}
              />
            </div>
            <div className="btn_wrap">
              <Button
                text="login with wallet"
                onClick={() => navigate("../perawallet")}
              />
            </div>
          </div>
        </div>
      </div>
    </AuthTemplate>
  );
};

export default LoginIntro;
