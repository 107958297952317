import React from "react";
import { NavButton } from "../../imports/component.imports";
import { headerContent, footerContent, Trailer, LoginBackground } from "../../utils/constant.utils";
import uuid from "react-uuid";
import "./auth_template.component.scss";

const AuthTemplate = (props) => {
  const { children } = props;
  return (
    <div className="auth_template_component">
      <div className="auth_template_container">
        <div className="auth_template_wrapper">
          <div className="auth_template_content_left">
            <video id="background-video" autoPlay muted loop src={LoginBackground.src}></video>
            <div className="auth_template_player_video_wrapper">
              <iframe title="trailer" className='auth_template_player_video' src={Trailer.src+"?autoplay=1&mute=1&controls=1"}  allow="autoplay; encrypted-media"></iframe>
            </div>
          </div>
          <div className="auth_template_content_right">
            <div className="auth_template_right_wrapper">
              <div className="auth_template_header_content">
                <div className="auth_template_header_content_wrapper">
                  {headerContent.map((header) => (
                    <div className="nav_btn_content" key={uuid()}>
                      <NavButton header={header} />
                    </div>
                  ))}
                </div>
              </div>
              <div className="auth_template_body_content">
                <div className="auth_template_form_content">{children}</div>
                <div className="auth_template_footer_content">
                  <div className="auth_template_footer_content_wrapper">
                    <div className="auth_footer_text">PARTNERS</div>
                    <div className="auth_footer_partners_wrapper">
                      {footerContent.map((partner) => (
                        <div className="footer_partner_content" key={uuid()}>
                          <img
                            src={partner.icon}
                            alt="partner_icon"
                            className="partner_logo"
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthTemplate;
