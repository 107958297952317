import { Navigate, Outlet } from "react-router-dom";

export { PrivateRoute };

function PrivateRoute({ children, path }) {
  const token = sessionStorage.getItem("token");

  if (!token) {
    // not logged in so redirect to login page with the return url
    return <Navigate to="/login" />;
  } else {
    // authorized so return child components
    // return children;
    return <Outlet />
  }
}
