import React from "react";
import "./confirm_pop.component.scss";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { Button } from "../../imports/component.imports";

const ConfirmPop = (props) => {
  const { open, onClose, title, header, btnText, onConfirm } = props;
  return (
    <Modal
      open={open}
      onClose={onClose}
      center
      classNames={{ modal: "confirm_pop_container" }}
    >
      <div className="confirm_pop_wrapper">
        {header && (
          <div className="header_content text_center head3">{header}</div>
        )}
        <div className="title_content text_center para4">{title}</div>
        <div className="btn_container">
          <div className="btn_wrapper">
            <Button text="cancel" onClick={onClose} />
          </div>
          <div className="btn_wrapper">
            <Button text={btnText || "Confirm"} onClick={onConfirm} />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmPop;
