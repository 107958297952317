import React from "react";
import { Modal } from "react-responsive-modal";
import Assets from "../../imports/assets.imports";
import { Button } from "../../imports/component.imports";
import "./subscripe_modal.component.scss";

const Subscript = (props) => {
  const { open, onClick, logout } = props;
  return (
    <Modal
      open={open}
      closeOnEsc={false}
      showCloseIcon={false}
      onClose={() => console.log("")}
      classNames={{
        root: "subscripe_modal_container",
        overlay: "subscripe_modal_overlay",
        modal: "subscripe_modal_content",
      }}
    >
      <div className="subscripe_modal_content_container">
        <img
          src={Assets.SubscriptionImage}
          alt="subscribe_background"
          className="subscripe_background"
        />
        <div className="subscripe_header_content">
          <div className="subscripe_header_wrapper">
            <img
              alt="subscripe_header_image"
              className="subscripe_header_image"
              src={Assets.LogoWhite}
            />
            <div className="logout_btn">
              <Button text="Logout" onClick={logout} borderRadius="4px" />
            </div>
          </div>
        </div>
        <div className="subscripe_body_content">
          <div className="body_head_text">
            <div className="top_head">Saad & Ragheb</div>
            <div className="middle_head">are waiting for you</div>
          </div>
          <div className="body_description_text">
            Buy the Tickets to watch the full concert live & interact with the
            stars
          </div>
          <div className="body_btn_content">
            <Button
              text="buy now"
              height="54px"
              padding="0 4em"
              borderRadius="4px"
              fontWeight="900"
              onClick={onClick}
            />
          </div>
        </div>
      </div>
      <div className="border_bottom" />
    </Modal>
  );
};

export default Subscript;
