import { Navigate, Outlet } from "react-router-dom";
import Auth from "../screens/auth/login/login.screen";

export { AuthRoute };
const authRoute = ["/login", "/email", "/email_validate", "/perawallet"];

function AuthRoute() {
  const token = sessionStorage.getItem("token");
  const path = window.location.pathname;
  if (token) {
    // not logged in so redirect to login page with the return url
    return <Navigate to="/" />;
  } else if (authRoute.includes(path)) {
    // authorized so return child components
    return <Outlet />;
  } else {
    return <Auth />;
  }
}
