// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

import Assets from "../imports/assets.imports";

export const AVATARS = [
  {
    name: "bear",
    src: "https://d39ii5l128t5ul.cloudfront.net/assets/animals_square/bear.png",
  },
  {
    name: "bird",
    src: "https://d39ii5l128t5ul.cloudfront.net/assets/animals_square/bird.png",
  },
  {
    name: "bird2",
    src: "https://d39ii5l128t5ul.cloudfront.net/assets/animals_square/bird2.png",
  },
  {
    name: "dog",
    src: "https://d39ii5l128t5ul.cloudfront.net/assets/animals_square/dog.png",
  },
  {
    name: "giraffe",
    src: "https://d39ii5l128t5ul.cloudfront.net/assets/animals_square/giraffe.png",
  },
  {
    name: "hedgehog",
    src: "https://d39ii5l128t5ul.cloudfront.net/assets/animals_square/hedgehog.png",
  },
  {
    name: "hippo",
    src: "https://d39ii5l128t5ul.cloudfront.net/assets/animals_square/hippo.png",
  },
];

export const STICKERS = [
  {
    name: "cute",
    src: "https://d39ii5l128t5ul.cloudfront.net/assets/chat/v1/sticker-1.png",
  },
  {
    name: "angry",
    src: "https://d39ii5l128t5ul.cloudfront.net/assets/chat/v1/sticker-2.png",
  },
  {
    name: "sad",
    src: "https://d39ii5l128t5ul.cloudfront.net/assets/chat/v1/sticker-3.png",
  },
  {
    name: "happy",
    src: "https://d39ii5l128t5ul.cloudfront.net/assets/chat/v1/sticker-4.png",
  },
  {
    name: "surprised",
    src: "https://d39ii5l128t5ul.cloudfront.net/assets/chat/v1/sticker-5.png",
  },
  {
    name: "cool",
    src: "https://d39ii5l128t5ul.cloudfront.net/assets/chat/v1/sticker-6.png",
  },
  {
    name: "love",
    src: "https://d39ii5l128t5ul.cloudfront.net/assets/chat/v1/sticker-7.png",
  },
  {
    name: "rocket",
    src: "https://d39ii5l128t5ul.cloudfront.net/assets/chat/v1/sticker-8.png",
  },
  {
    name: "confetti",
    src: "https://d39ii5l128t5ul.cloudfront.net/assets/chat/v1/sticker-9.png",
  },
  {
    name: "camera",
    src: "https://d39ii5l128t5ul.cloudfront.net/assets/chat/v1/sticker-10.png",
  },
];

export const headerContent = [
  {
    icon: Assets.FB,
    link: "https://m.facebook.com/MetaBoundless/",
  },
  {
    icon: Assets.Twitter,
    link: "https://twitter.com/metaboundless",
  },
  {
    icon: Assets.Instagram,
    link: "https://instagram.com/metaboundless?igshid=YmMyMTA2M2Y=",
  },
  {
    icon: Assets.Youtube,
    link: "https://www.youtube.com/channel/UC2hFf95w_AaJk6QShcZEu5A",
  },
  // {
  //   icon: Assets.Reddit,
  //   link: ""
  // },
];

export const footerContent = [
  {
    icon: Assets.Street,
  },
  {
    icon: Assets.Algorand,
  },
  {
    icon: Assets.Apparel,
  },
  {
    icon: Assets.Crypto,
  },
];

export const otpContainerStyle = {
  display: "flex",
  justifyContent: "space-evenly",
  padding: "1em 0",
};

export const otpInputStyle = {
  width: "35px",
  height: "35px",
  borderRadius: "8px",
  border: "1px solid rgba(0, 0, 0, 0.2)",
  outline: "none",
  margin: "0.2em",
};

export const otpFocusStyle = {
  border: "1px solid rgba(0, 0, 0, 0.7)",
};

export const otpErrorStyle = {
  border: "1px solid red",
};

export const sampleMessages = [
  {
    name: "Mukunthan",
    message: "Hi all!",
  },
  {
    name: "Raju",
    message: "Hi all!",
  },
  {
    name: "Arun",
    message: "Hi all!",
  },
  {
    name: "Natarajan",
    message: "Hi all!",
  },
  {
    name: "Praveen",
    message: "Hi all!",
  },
  {
    name: "Simyon",
    message: "Hi all!",
  },
  {
    name: "Manoj",
    message: "Hi all!",
  },
  {
    name: "Karthick",
    message: "Hi all!",
  },
  {
    name: "Rajan",
    message: "Hi all!",
  },
  {
    name: "Mukunthan",
    message: "Hi all!",
  },
  {
    name: "Vinayak",
    message: "Hi all!",
  },
  {
    name: "Sathish",
    message: "Hi all!",
  },
  {
    name: "Mani",
    message: "Hi all!",
  },
];

const Videos = [
  {
    trailer: {
      src: "https://metaboundless-assets-public.s3.ap-south-1.amazonaws.com/MetaBoundless_Main_Trailer.mp4",
    },
  },
  {
    loginBackground: {
      src: "https://metaboundless-assets-public.s3.ap-south-1.amazonaws.com/MetaBoundless_Background_Stick_Video.mp4",
    },
  },
  {
    playerBackground: {
      src: "https://metaboundless-assets-public.s3.ap-south-1.amazonaws.com/MetaBoundless_AboutUs_Video_Final.mp4",
    },
  },
];

export const Trailer = Videos[0].trailer;
export const LoginBackground = Videos[1].loginBackground;
export const PlayerBackground = Videos[2].playerBackground;
