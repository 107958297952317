import { ErrorMessage, Field } from "formik";
import React from "react";
import Error from "../error/error.ui";
import "./input.ui.scss";

const Input = (props) => {
  const {
    name,
    value,
    onChange,
    icon,
    iconPosition,
    placeHolder,
    backgroundColor,
    borderRadius,
    field,
    length,
    disabled,
    padding,
    type,
    color,
  } = props;
  return !field ? (
    <div className="input_container">
      {icon && iconPosition === "start" && <div />}
      <div className="field_wrapper">
        <input
          className="input_field"
          id="input_field"
          name={name}
          style={{
            backgroundColor: backgroundColor,
            borderRadius: borderRadius,
            padding: padding,
            color: color,
          }}
          disabled={disabled}
          type={type || "text"}
          value={value}
          placeholder={placeHolder}
          maxLength={length}
          onChange={onChange}
        />
      </div>
      {icon && iconPosition === "end" && <div />}
    </div>
  ) : (
    <div className="input_container">
      {icon && iconPosition === "start" && <div />}
      <div className="field_wrapper">
        <Field
          className="input_field"
          name={name}
          disabled={disabled}
          autoComplete={"off"}
          placeholder={placeHolder}
          maxLength={length}
        />
      </div>
      <ErrorMessage name={name} component={Error} />
      {icon && iconPosition === "end" && <div />}
    </div>
  );
};

export default Input;
