import React from "react";
import Assets from "../../imports/assets.imports";

const Loader = () => {
  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img
        src={Assets.PageLoader}
        alt="page_loader"
        style={{
          width: "150px",
          height: "150px",
        }}
      />
    </div>
  );
};

export default Loader;
