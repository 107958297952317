import React from "react";
import { Button } from "../../imports/component.imports";
import "./player_error.component.scss";

const PlayerError = (props) => {
  const { onRefresh } = props;
  return (
    <div className="player_error_component">
      <div className="player_error_wrapper">
        <div className="head3 text_center white_text error_message">
          Please Refresh to watch!
        </div>
        <div className="btn_container">
          <Button text="Refresh" onClick={onRefresh} />
        </div>
      </div>
    </div>
  );
};
export default PlayerError;
