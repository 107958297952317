import axios from "axios";

const client = async () => {
  const token = sessionStorage.getItem("token");
  const instance = await axios.create({
    baseURL: process.env.REACT_APP_SERVER_URI,
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      authorization: "Bearer " + token,
    },
  });
  instance.interceptors.response.use(undefined, (error) => {
    const statusCode = error.response ? error.response.status : null;
    let path = window.location.pathname;
    const authRoute = ["/login", "/email", "/email_validate", "/perawallet"];
    if (statusCode === 401) {
      sessionStorage.clear();
      localStorage.clear();
    }
    if (statusCode === 401 && !authRoute.includes(path)) {
      window.location.href = "/login";
    }
    return Promise.reject(error);
  });
  return instance;
};

export default client;
