import React from "react";
import "./nav_btn.ui.scss";

const NavButton = (props) => {
  const { header, width, height, borderRadius } = props;
  return (
    <div
      className="nav_btn_ui_container"
      style={{
        width: width,
        height: height,
        borderRadius: borderRadius,
      }}
    >
      <div className="nav_btn_ui_wrapper">
        <a href={header.link} target="blank">
          <img className="nav_btn_icon" src={header.icon} alt="nav_btn_icon" />
        </a>
      </div>
    </div>
  );
};

export default NavButton;
