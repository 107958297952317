import { createSlice } from "@reduxjs/toolkit";

const user = {
  userDetails: {},
};

export const userSlice = createSlice({
  name: "user",
  initialState: user,
  reducers: {
    replaceUser: (state, action) => {
      state.userDetails = action.payload;
    },
  },
});

export const { replaceUser } = userSlice.actions;

export default userSlice.reducer;
