import "./App.scss";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import React, { useEffect, Suspense, lazy } from "react";
import { PrivateRoute } from "./utils/private.route";
import { AuthRoute } from "./utils/authRoute.utils";
import Models from "./imports/models.imports";
import { useDispatch } from "react-redux";
import { setIOS } from "./redux/walletSlice";
import { UAParser } from "ua-parser-js";
import Loader from "./components/loader/loader.component";
const Dashboard = lazy(() =>
  import("./screens/new_dashboard/dashboard.screen")
);
const Player = lazy(() => import("./screens/player/player.screen"));
const LoginIntro = lazy(() => import("./screens/auth/login/login.screen"));
const EmailLogin = lazy(() =>
  import("./screens/auth/email_login/email_login.screen")
);
const VerifyOtp = lazy(() => import("./screens/auth/otp/otp.screen"));
const PeraWallet = lazy(() =>
  import("./screens/auth/pera_login/pera_login.screen")
);

function App() {
  const navigation = useNavigate();
  const location = useLocation();
  const token = sessionStorage.getItem("token");
  const dispatch = useDispatch();

  useEffect(() => {
    const ClientUAInstance = new UAParser();
    const os = ClientUAInstance.getOS();
    let iOS = false;
    if (os?.name === "Mac OS" || os?.name === "iOS") {
      iOS = true;
    }
    dispatch(setIOS(iOS));
  }, [dispatch]);

  useEffect(() => {
    // window.addEventListener("contextmenu", (ev) => {
    //   ev.preventDefault();
    // });
    if (token) verifyUser();
  }, [token]);

  const verifyUser = async () => {
    try {
      await Models.Auth.verifyUser();
      return true;
    } catch (err) {
      if (sessionStorage.removeItem("email"))
        sessionStorage.removeItem("email");
      sessionStorage.removeItem("token");
      if (sessionStorage.getItem("wallet")) {
        sessionStorage.removeItem("wallet");
        sessionStorage.removeItem("PeraWallet.Wallet");
        sessionStorage.removeItem("PeraWallet.BridgeURL");
        sessionStorage.removeItem("walletconnect");
        localStorage.removeItem("walletconnect");
        sessionStorage.removeItem("walletAddress");
      }
      let loc = location.pathname.split("/").filter((path) => path);
      if (loc[0] !== "/login" && loc.length > 0) {
        navigation("../login");
      } else {
        navigation("/login");
      }
    }
  };

  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        <Route path="/" element={<PrivateRoute />}>
          <Route index element={<Dashboard />} />
          <Route path="/live" element={<Player />} />
        </Route>
        <Route path="/" element={<AuthRoute />}>
          <Route path="login" element={<LoginIntro />} />
          <Route path="email" element={<EmailLogin />} />
          <Route path="email_validate" element={<VerifyOtp />} />
          <Route path="perawallet" element={<PeraWallet />} />
        </Route>
      </Routes>
    </Suspense>
  );
}

export default App;
