import React from "react";
import Assets from "../../../imports/assets.imports";
import "./button.ui.scss";

const Button = (props) => {
  const {
    onClick,
    text,
    type,
    disabled,
    width,
    height,
    padding,
    loading,
    borderRadius,
    fontWeight
  } = props;
  return loading ? (
    <div
      className="primary_button btn_loading"
      style={{
        width: width,
        height: height,
        padding: padding,
      }}
    >
      <img
        src={Assets.BtnSpinner}
        className="btn_loading_icon"
        alt="btn_loading"
      />
    </div>
  ) : (
    <button
      className="primary_button"
      disabled={disabled}
      onClick={onClick}
      type={type || "button"}
      style={{
        width: width,
        height: height,
        padding: padding,
        borderRadius: borderRadius,
        fontWeight: fontWeight,
      }}
    >
      {text}
    </button>
  );
};

export default Button;
