import React from "react";
import Assets from "../../imports/assets.imports";
import "./message.component.scss";

const Message = (props) => {
  const { message, name, avatar } = props;

  function replaceUrl(text) {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.split(urlRegex).map((part) => {
      if (part.match(urlRegex)) {
        return (
          <a
            href={part}
            key={part}
            target="_blank"
            rel="noreferrer"
            className="message_url hyber_link"
          >
            {" "}
            {part}{" "}
          </a>
        );
      }
      return part;
    });
  }

  function getProfilePicture(profilePic) {

    if (profilePic === "saad") {
      return Assets.saadAvatar;
    } else if (profilePic === "ragheb") {
      return Assets.raghebAvatar;
    } else if (profilePic === "michel") {
      return Assets.michelAvatar;
    } else {
      return Assets.raghebAvatar;
    }
  }
  return (
    <div className="message_componet">
      <div className="message_container">
        <div className="message_wrapper">
          <div className="message_profile_content">
            <div
              style={{ backgroundImage: Assets.saadAvatar }}
              className="message_profile_picture"
            >
              <img src={getProfilePicture(avatar)} alt="" />
            </div>
          </div>
          <div className="message_content">
            <div className="messager_name head3">{name}</div>
            <div className="message_details">{replaceUrl(message)}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(Message);
