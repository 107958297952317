import React from "react";

const Error = (props) => {
    return (
      <div
        style={{
          color: "red",
          fontWeight: 300,
          padding: "0.2em 0 0.2em 0.8em",
          width: "100%",
          fontFamily: "Poppins",
        }}
      >
        {props.children}
      </div>
    );
}

export default Error;