import React, { useEffect, useState, useRef } from "react";
import Assets from "../../imports/assets.imports";
import { Input, Message } from "../../imports/component.imports";
import "./chat.component.scss";
import uuid from "react-uuid";

const Chat = (props) => {
  const { onClose, disable, ivsMessages, sendMessage } = props;
  const messagesEndRef = useRef(null);
  const [message, setMessage] = useState("");

  useEffect(() => {
    const input = document.getElementById("input_field");
    input.addEventListener("keyup", onEnter);
    return () => {
      input.removeEventListener("keyup", onEnter);
    };
  });

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({
      block: "end",
      inline: "nearest",
    });
  };

  useEffect(() => {
    scrollToBottom();
  }, [ivsMessages]);

  const onEnter = (ev) => {
    if (ev.key === "Enter" || ev.keyCode === 13) {
      if (message.length > 0) {
        sendMessage(message);
        setMessage("");
      }
    }
  };

  return (
    <div className="chat_component">
      <div className="chat_container">
        <div className="chat_wrapper">
          <div className="chat_header_content">
            <div className="head2 text_center header_text">Live Chat</div>
          </div>
          <div className="chat_body_content">
            {disable ? (
              <div className="spinner">
                <img src={Assets.PageLoader} alt="chat_loader" className="chat_loader" />
              </div>
            ) : (
              <div className="chat_message_content">
                {ivsMessages.map((msg) => (
                  <Message
                    name={msg.Sender?.Attributes.username}
                    message={msg.Content}
                    key={uuid()}
                    avatar={msg.Sender?.Attributes?.avatar || "saad"}
                  />
                ))}
                <div ref={messagesEndRef} />
              </div>
            )}
            <div className="chat_input_container">
              <div className="chat_input_content_wrapper">
                <div className="message_input">
                  <Input
                    placeHolder="Say something..."
                    backgroundColor="#FFFFFF33"
                    borderRadius="40px"
                    type="textarea"
                    color="#FFFFFF"
                    padding="0 50px 0 1em"
                    value={message}
                    onChange={(e) => {
                      setMessage(e.target.value);
                    }}
                    disabled={disable}
                  />
                  <div
                    className="message_send_icon_container"
                    style={{
                      pointerEvents: message.length === 0 ? "none" : "auto",
                    }}
                  >
                    <img
                      src={Assets.Send}
                      alt="send_message_icon"
                      className="send_message_icon"
                      onClick={() => {
                        sendMessage(message);
                        setMessage("");
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="chat_clost_btn_content" onClick={onClose}>
          <div className="close_btn_wrapper">
            <img
              className="close_btn_icon"
              src={Assets.Close}
              alt="chat_component_close_icon"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Chat;
