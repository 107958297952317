import axios from "axios";
import client from "../utils/axios.util";

const Auth = {
  login: async (data) => {
    try {
      const user = await (await client()).post("/users/login", data);
      return user.data;
    } catch (err) {
      if (err?.response?.data?.message) {
        throw err?.response?.data?.message;
      } else if (err?.message) {
        throw err?.message;
      } else {
        throw err;
      }
    }
  },
  verifyOtp: async (data) => {
    try {
      const user = await (await client()).post("/users/login/verify", data);
      return user.data;
    } catch (err) {
      // console.log("err", err);
      if (err?.response?.data?.message) {
        throw err?.response?.data?.message;
      } else if (err?.message) {
        throw err?.message;
      } else {
        throw err;
      }
    }
  },
  googleLogin: async (data) => {
    try {
      const user = await (await client()).post("/users/login/oauth", data);
      return user.data;
    } catch (err) {
      // console.log("err", err);
      if (err?.response?.data?.message) {
        throw err?.response?.data?.message;
      } else if (err?.message) {
        throw err?.message;
      } else {
        throw err;
      }
    }
  },
  verifyNFT: async (data) => {
    try {
      const user = await (await client()).post("/users/login/nft", data);
      return user.data;
    } catch (err) {
      // console.log("err", err);
      if (err?.response?.data?.message) {
        throw err?.response?.data?.message;
      } else if (err?.message) {
        throw err?.message;
      } else {
        throw err;
      }
    }
  },
  getPlayBackURL: async () => {
    try {
      const user = await (await client()).post("/aws_ivs/sign");
      return user.data;
    } catch (err) {
      // console.log("err", err);
      if (err?.response?.data?.message) {
        throw err?.response?.data?.message;
      } else if (err?.message) {
        throw err?.message;
      } else {
        throw err;
      }
    }
  },
  verifyUser: async (data) => {
    try {
      const user = await (await client()).post("/users/verify", data);
      return user.data;
    } catch (err) {
      // console.log("err", err);
      if (err?.response?.data?.message) {
        throw err?.response?.data?.message;
      } else if (err?.message) {
        throw err?.message;
      } else {
        throw err;
      }
    }
  },
  updateStatus: async (data) => {
    try {
      const user = await (await client()).post("/users/watching", data);
      return user.data;
    } catch (err) {
      console.log("err", err);
      if (err?.response?.data?.message) {
        throw err?.response?.data?.message;
      } else if (err?.message) {
        throw err?.message;
      } else {
        throw err;
      }
    }
  },
  logout: async () => {
    try {
      const user = await (await client()).delete("/users/logout");
      return user.data;
    } catch (err) {
      if (err?.response?.data?.message) {
        throw err?.response?.data?.message;
      } else if (err?.message) {
        throw err?.message;
      } else {
        throw err;
      }
    }
  },
  chatToken: async (data) => {
    try {
      const token = await axios.post(
        `${process.env.REACT_APP_CHAT_BACKEND_API_URL}/auth`,
        data,
        {
          headers: {
            authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      );
      return token.data;
    } catch (err) {
      if (err?.response?.data?.message) {
        throw err?.response?.data?.message;
      } else if (err?.message) {
        throw err?.message;
      } else {
        throw err;
      }
    }
  },
  getUser: async () => {
    try {
      const user = await (await client()).post("/users/get");
      return user.data;
    } catch (err) {
      if (err?.response?.data?.message) {
        throw err?.response?.data?.message;
      } else if (err?.message) {
        throw err?.message;
      } else {
        throw err;
      }
    }
  },
};

export default Auth;
