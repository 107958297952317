import BtnSpinner from "../assets/loaders/Spinner.svg";
import PageLoader from "../assets/loaders/page_loader.svg";
import Algorand from "../assets/icons/algorand.svg";
import Apparel from "../assets/icons/apparel.svg";
import Street from "../assets/icons/street_logo.svg";
import Crypto from "../assets/icons/crypto.svg";
// import MainImage from "../assets/icons/main_img.svg";
import MainImage from "../assets/images/main_img.jpg";
import AlgorandLogo from "../assets/icons/algorand_logo.svg";
import PeraLogo from "../assets/icons/pera_logo.svg";
// import MainBg from "../assets/icons/main_bg.svg";
import MainBg from "../assets/images/main_bg.jpg";
import FB from "../assets/icons/fb.svg";
import Instagram from "../assets/icons/insta.svg";
import Reddit from "../assets/icons/reddit.svg";
import Twitter from "../assets/icons/twitter.svg";
import Youtube from "../assets/icons/youtube.svg";
import Logo from "../assets/icons/logo.svg";
import LogoWhite from "../assets/icons/logo_white.svg";
import ArrowRight from "../assets/icons/arrow.svg";
import Message from "../assets/icons/message.svg";
import Close from "../assets/icons/close.svg";
import Send from "../assets/icons/send.svg";
import SubscriptionImage from "../assets/images/bg.png";
import SubscriptionImageMobile from "../assets/images/mobile_bg.png";
import saadAvatar from "../assets/images/saad_avatar.png"
import raghebAvatar from "../assets/images/ragheb_avatar.png"
import michelAvatar from "../assets/images/michel_avatar.png"
import msgIcon from "../assets/icons/msg.svg"
const Assets = {
  BtnSpinner,
  PageLoader,
  Algorand,
  Apparel,
  Street,
  Crypto,
  MainImage,
  AlgorandLogo,
  PeraLogo,
  MainBg,
  Youtube,
  Twitter,
  Reddit,
  Instagram,
  FB,
  Logo,
  LogoWhite,
  ArrowRight,
  Message,
  Close,
  Send,
  SubscriptionImage,
  SubscriptionImageMobile,
  saadAvatar,
  raghebAvatar,
  michelAvatar,
  msgIcon
};

export default Assets;
